export const dic = [
{'key': 1, 'name': '汉族', 'code': 'HA', 'py': 'hz hanzu'},
{'key': 2, 'name': '蒙古族', 'code': 'MG', 'py': 'mgz mengguzu'},
{'key': 3, 'name': '回族', 'code': 'HU', 'py': 'hz huizu'},
{'key': 4, 'name': '藏族', 'code': 'ZA', 'py': 'zz zangzu'},
{'key': 5, 'name': '维吾尔族', 'code': 'UG', 'py': 'wwez weiwuerzu'},
{'key': 6, 'name': '苗族', 'code': 'MH', 'py': 'mz miaozu'},
{'key': 7, 'name': '彝族', 'code': 'YI', 'py': 'yz yizu'},
{'key': 8, 'name': '壮族', 'code': 'ZH', 'py': 'zz zhuangzu'},
{'key': 9, 'name': '布依族', 'code': 'BY', 'py': 'byz buyizu'},
{'key': 10, 'name': '朝鲜族', 'code': 'CS', 'py': 'cxz chaoxianzu'},
{'key': 11, 'name': '满族', 'code': 'MA', 'py': 'mz manzu'},
{'key': 12, 'name': '侗族', 'code': 'DO', 'py': 'dz dongzu'},
{'key': 13, 'name': '瑶族', 'code': 'YA', 'py': 'yz yaozu'},
{'key': 14, 'name': '白族', 'code': 'BA', 'py': 'bz baizu'},
{'key': 15, 'name': '土家族', 'code': 'TJ', 'py': 'tjz tujiazu'},
{'key': 16, 'name': '哈尼族', 'code': 'HN', 'py': 'hnz hainizu'},
{'key': 17, 'name': '哈萨克族', 'code': 'KZ', 'py': 'hskz hasakezu'},
{'key': 18, 'name': '傣族', 'code': 'DA', 'py': 'dz daizu'},
{'key': 19, 'name': '黎族', 'code': 'LI', 'py': 'lz lizu'},
{'key': 20, 'name': '傈僳族', 'code': ' LS', 'py': 'lsz lisuzu'},
{'key': 21, 'name': '佤族', 'code': 'VA', 'py': 'wz wazu'},
{'key': 22, 'name': '畲族', 'code': 'SH', 'py': 'sz shezu'},
{'key': 23, 'name': '高山族', 'code': 'GS', 'py': 'gsz gaoshanzu'},
{'key': 24, 'name': '拉祜族', 'code': 'LH', 'py': 'lgz laguzu'},
{'key': 25, 'name': '水族', 'code': 'SU', 'py': 'sz shuizu'},
{'key': 26, 'name': '东乡族', 'code': 'DX', 'py': 'dxz dongxiangzu'},
{'key': 27, 'name': '纳西族', 'code': 'NX', 'py': 'nxz naxizu'},
{'key': 28, 'name': '景颇族', 'code': 'JP', 'py': 'jpz jingpozu'},
{'key': 29, 'name': '柯尔克孜族', 'code': 'KG', 'py': 'kekzz keerkezizu'},
{'key': 30, 'name': '土族', 'code': 'TU', 'py': 'tz tuzu'},
{'key': 31, 'name': '达斡尔族', 'code': 'DU', 'py': 'dhez dahanerzu'},
{'key': 32, 'name': '仫佬族', 'code': 'ML', 'py': 'mlz mulaozu'},
{'key': 33, 'name': '羌族', 'code': 'QI', 'py': 'qz qiangzu'},
{'key': 34, 'name': '布朗族', 'code': 'BL', 'py': 'blz bulangzu'},
{'key': 35, 'name': '撒拉族', 'code': 'SL', 'py': 'slz salazu'},
{'key': 36, 'name': '毛难族', 'code': 'MN', 'py': 'mnz maonanzu'},
{'key': 37, 'name': '仡佬族', 'code': 'GL', 'py': 'qlz qilaozu'},
{'key': 38, 'name': '锡伯族', 'code': 'XB', 'py': 'xbz xibozu'},
{'key': 39, 'name': '阿昌族', 'code': 'AC', 'py': 'acz achangzu'},
{'key': 40, 'name': '普米族', 'code': 'PM', 'py': 'pmz pumizu'},
{'key': 41, 'name': '塔吉克族', 'code': 'TA', 'py': 'tjkz tajikezu'},
{'key': 42, 'name': '怒族', 'code': 'NU', 'py': 'nz nuzu'},
{'key': 43, 'name': '乌孜别克族', 'code': 'UZ', 'py': 'mgwzbkz wuzibiekezu'},
{'key': 44, 'name': '俄罗斯族', 'code': 'RS', 'py': 'elsz eluosizu'},
{'key': 45, 'name': '鄂温克族', 'code': 'EW', 'py': 'ewkz ewenkezu'},
{'key': 46, 'name': '德昂族', 'code': 'DE', 'py': 'daz deangzu'},
{'key': 47, 'name': '保安族', 'code': 'BN', 'py': 'baz baoanzu'},
{'key': 48, 'name': '裕固族', 'code': 'YG', 'py': 'ygz yuguzu'},
{'key': 49, 'name': '京族', 'code': 'GI', 'py': 'jz jingzu'},
{'key': 50, 'name': '塔塔尔族', 'code': 'TT', 'py': 'ttez tataerzu'},
{'key': 51, 'name': '独龙族', 'code': 'DR', 'py': 'dlz dulongzu'},
{'key': 52, 'name': '鄂伦春族', 'code': 'OR', 'py': 'elcz elunchunzu'},
{'key': 53, 'name': '赫哲族', 'code': 'HZ', 'py': 'hzz hezhezu'},
{'key': 54, 'name': '门巴族', 'code': 'MB', 'py': 'mbz menbazu'},
{'key': 55, 'name': '珞巴族', 'code': 'LB', 'py': 'lbz luobazu'},
{'key': 56, 'name': '基诺族', 'code': 'JN', 'py': 'jnz jinuozu'}
]

export var filter = function (key, reduce = (i) => { return i}) {
    return dic.filter(i=>{
        return (i['name']+i['py']).indexOf(key) != -1
    }).map(i => {
        return reduce(i)
    })
}

export default { dic, filter }