<template>
  <div class="c_dialog">
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      title="修改博主信息"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :modal="false"
    >
      <editor_form ref="editor" :amInfo="amInfo" @cancel="dismiss"/>
    </el-dialog>
  </div>
</template>

<script>
import editor_form from './editor_form.vue';

import { loadAmInfoById } from '@/api/anchorman';

export default {
  components: { editor_form },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      amInfo: {},
      tip: '',
    };
  },
  computed: {
    
  },
  methods: {
    async show(id) {
      //需要重新抓取dy信息
      await this.fetchAm(id)
      this.dialogVisible = true;
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) {
          return false;
        }

        //根据抖音博主secId解析用户数据
        const [info, err] = await this.getDyUserInfo(this.form.uri)
        if (err) {
          this.tip = `从抖音获取博主信息时发生错误，稍后再试！原因：${err}。`
          return
        }

        this.amInfo = info
        this.step = 1

        //console.log(info);
        this.loading = false
      });
    },
    async fetchAm(id) {
      const { status, content, msg } = await loadAmInfoById(id)
      if (status != -1) {
        this.$message({
          type: "error",
          title: `获取博主数据(${id})错误，原因：${msg}。`,
        });
        return;
      }

      this.amInfo = content
    },
    resetForm() {
      this.$refs.editor.resetForm && this.$refs.editor.resetForm()
    },
    dismiss() {
      this.dialogVisible = false;
      this.resetForm()
      this.$emit('refresh')
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.resetForm()
      done()
    },
  },
};
</script>
<style lang="scss">
.c_dialog {
  .el-dialog {
    border-radius: 20px !important;
    background-color: rgba(25, 25, 25, 0.98);
    box-shadow: 0 2px 6px rgb(250 250 250 / 20%);
  }
  .el-divider__text {
    color: white;
    border-radius: 10px !important;
    background-color: rgba(25, 25, 25, 0.98);
  }
}
</style>
