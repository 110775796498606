<template>
  <div class="c_dialog">
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      title="添加博主"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :modal="false"
    >
      <div v-if="step == 0">
        <el-form v-loading="loading" element-loading-background="rgba(10, 10, 10, 0.7)" ref="form" :model="form" :rules="rules">
            <label>博主首页地址或安全ID：</label>
            <el-form-item label="" prop="uri">
              <el-input v-model="form.uri" placeholder="博主首页地址或安全ID" />
            </el-form-item>
        </el-form>
        
        <el-alert v-if="tip" :title="tip" type="error" effect="dark">
        
        </el-alert>
        <el-divider />
        <div style="display: flex;justify-content: center">
          <el-button :loading="loading" type="primary" @click="onSubmit('form')">下一步</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
          <el-button @click="dismiss">取消</el-button>
        </div>
      </div>

      <editor_form v-if="step == 1" ref="editor" :amInfo="amInfo" @cancel="dismiss"/>
    </el-dialog>
  </div>
</template>

<script>
import { getDyUser } from '@/api/anchorman';
import editor_form from './editor_form.vue';

export default {
  components: { editor_form  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      step: 0,
      amInfo: {},
      form: {
        uri: ''//"MS4wLjABAAAAoZJlzqfmnZDgYtvWUJzw6gu8wb8Zgwdw-F60FWOe5tk"
      },
      tip: '',
      rules: {
        uri: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: async (r, v, c, s) => {
              const uri = s.uri;
              if (!uri || uri.length < 55) {
                return c(new Error("不能少于55个字符！"));
              }

              c();
            },
          },
        ]
      },
    };
  },
  methods: {
    async show() {
      this.dialogVisible = true;
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) {
          return false;
        }

        //根据抖音博主secId解析用户数据
        const [info, err] = await this.getDyUserInfo(this.form.uri)
        if(err) {
          this.tip = `从抖音获取博主信息时发生错误，稍后再试！原因：${err}。`
          return
        }

        this.amInfo = info
        this.step = 1

        //console.log(info);
        this.loading = false
      });
    },
    async getDyUserInfo(uri) {
      const secId = uri.substr(uri.lastIndexOf('/') + 1, uri.length)
      this.loading = true
      const info = await getDyUser(secId)
      this.loading = false
      if(info['status'] != -1) {
        return [null, info['msg']]
      }

      return [info['content'], null]
    },
    resetForm(formName) {
      this.step < 1 && this.$refs[formName].resetFields();
      this.form = {
        uri: "",
      }

      this.step > 0 && this.$refs.editor.resetForm && this.$refs.editor.resetForm()

      this.step = 0
    },
    dismiss() {
      this.dialogVisible = false;
      this.resetForm('form')
    },
    handleClose(done) {
      this.dialogVisible = false;
      
      this.resetForm('form')
      done()
    },
  },
};
</script>
<style lang="scss">
.c_dialog {
  .el-dialog {
    border-radius: 20px !important;
    background-color: rgba(25, 25, 25, 0.98);
    box-shadow: 0 2px 6px rgb(250 250 250 / 20%);
  }
  .el-divider__text {
    color: white;
    border-radius: 10px !important;
    background-color: rgba(25, 25, 25, 0.98);
  }
}
</style>
