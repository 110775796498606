<template>
  <div>
    <div style="display: flex;justify-content:flex-start;">
      <div>
        <el-avatar
          shape="square"
          :size="120"
          fit="fill"
          :src="amInfo ? amInfo.avatar_uri : ''"
        />
      </div>
      <div
        style="margin-left: 20px;display: flex;flex-direction: column;justify-content: flex-start;"
      >
        <p style="font-weight: bolder;font-size: 24px">
          {{ amInfo["nickname"] }}
        </p>

        <p style="margin-top: 10px">{{ amInfo["signature"] }}</p>
      </div>
    </div>
    <div
      style="margin-top: 10px;margin-bottom: 20px; width:100%;font-size: 1.2rem;display:flex;justify-content: center;"
    >
      <span style="margin-right:10px"
        >关注 <b>{{ amInfo["following_count"] }}</b></span
      >
      <span style="margin-right:10px"
        >粉丝 <b>{{ amInfo["follower_count"] }}</b></span
      >
      <span style="margin-right:10px"
        >获赞 <b>{{ amInfo["total_favorited"] }}</b></span
      >
      <span style="margin-right:10px"
        >作品数 <b>{{ amInfo["aweme_count"] }}</b></span
      >
    </div>

    <el-form ref="form_edit" name="form_edit" :model="form" :rules="rules" label-width="80px">
      <input type="hidden" name="id" :value="form.id" />

      <div class="form_2col">
        <el-form-item label="名字" prop="name" style="min-width:70%">
          <el-input
            v-model="form.name"
            placeholder="输入名字"
            style="width: 100%"
            :clearable="true"
          />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select
            v-model="form.status"
            placeholder="状态"
            clearable
            style="width: 90px"
            class="filter-item"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
      </div>

      <div class="form_2col">
        <el-form-item label="性别" prop="gender">
          <el-radio v-model="form.gender" :label="0">未知</el-radio>
          <el-radio v-model="form.gender" :label="1">女</el-radio>
          <el-radio v-model="form.gender" :label="2">男</el-radio>
        </el-form-item>
        <el-form-item label="生日" prop="birthday">
          <el-date-picker
            v-model="form.birthday"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </div>

      <div class="form_2col">
        <el-form-item label="身份证" prop="card_id">
          <el-input
            v-model="form.card_id"
            placeholder="输入身份证"
            maxlength="20"
            :clearable="true"
          />
        </el-form-item>
        <el-form-item label="入职时间" prop="rzsj">
          <el-date-picker
            v-model="form.rzsj"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </div>

      <div class="form_2col">
        <el-form-item label="电话" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="输入电话"
            maxlength="20"
            :clearable="true"
          />
        </el-form-item>
        <el-form-item label="抖音直播间" prop="dy_live_id" label-width="160px">
          <el-input
            v-model="form.dy_live_id"
            placeholder="输入直播间号"
            maxlength="20"
            :clearable="true"
          />
        </el-form-item>
      </div>

      <div class="form_2col">
        <el-form-item label="民族" prop="mz">
          <!--el-input v-model="form.mz" placeholder="民族" maxlength="10" /-->
          <el-autocomplete
            class="inline-input"
            v-model="form.mz"
            :fetch-suggestions="mzSearch"
            placeholder="可输入拼音或部分字"
            :trigger-on-focus="false"
          />
        </el-form-item>
        <el-form-item label="学历" prop="xl">
          <el-select
            v-model="form.xl"
            placeholder="学历"
            clearable
            style="width: 100px"
            class="filter-item"
          >
            <el-option
              v-for="item in [
                '初中及以下',
                '高中/高职',
                '中专',
                '大专',
                '本科',
                '其它',
              ]"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="婚姻" prop="hyzt">
          <el-select
            v-model="form.hyzt"
            placeholder="婚姻"
            clearable
            style="width: 100px"
            class="filter-item"
          >
            <el-option
              v-for="item in ['未婚', '已婚', '离异', '其它']"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </div>

      <div class="form_2col">
        <el-form-item label="残疾证" prop="cjzs">
          <el-input
            v-model="form.cjzs"
            placeholder="输入残疾证"
            maxlength="25"
            :clearable="true"
          />
        </el-form-item>
        <el-form-item label="残疾类别" prop="cjlb">
          <el-select
            v-model="form.cjlb"
            placeholder="残疾类别"
            clearable
            style="width: 100px"
            class="filter-item"
          >
            <el-option
              v-for="item in ['肢体', '视力', '多重']"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="残疾等级" prop="cjdj">
          <el-select
            v-model="form.cjdj"
            placeholder="残疾等级"
            clearable
            style="width: 110px"
            class="filter-item"
          >
            <el-option
              v-for="item in ['一', '二', '三', '四', '五', '六']"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </div>

      <el-form-item label="地址" prop="province" style="width: 99%">
        <el-select
          v-model="addr"
          filterable
          clearable=""
          remote
          reserve-keyword
          placeholder="输入地址关键字"
          :remote-method="addrSearch"
          :loading="addrLoading"
          @change="onAddrChange"
          style="width: 99%"
        >
          <el-option
            v-for="item in addrOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div ref="tx_map_container" class="tMap" />
    </el-form>

    <el-alert v-if="tip" :title="tip" type="error" effect="dark">
    
    </el-alert>
    <el-divider />
    <div style="display: flex;justify-content: center">
      <el-button :loading="loading" type="primary" @click="onSubmit()">保存</el-button>
      <el-button @click="resetForm()">重置</el-button>
      <el-button @click="dismiss">取消</el-button>
    </div>
  </div>
</template>

<script>
import mz from "@/utils/mz";
import hash from "js-hash-code";
import { createAnchorman, updateAnchorman } from '@/api/anchorman'

const TMap = window.TMap;
export default {
  props: {
    amInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    idEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formHash: -1,
      form: {
        id: null,
        name: "",
        gender: 0,
        birthday: null,
        phone: null,
        status: 0,
        province: null,
        addr: {},
        lat: 0,
        lng: 0,
        latlng: "",
        card_id: null,
        cjzs: null,
        cjlb: "肢体",
        cjdj: null,
        xl: null,
        hyzt: null,
        mz: null,
        rzsj: null,
        dy_live_id: null,
        //---从抖音页面抓取
        dyid: null,
        dysecid: null,
        nickname: null,
        signature: '',
        avatar_uri: "",
        favoriting_count: 0,
        total_favorited: 0,
        follower_count: 0,
        aweme_count: 0,
        following_count: 0,
      },
      statusOptions: [
        { label: "正常", key: 0 },
        { label: "解约", key: -1 },
        { label: "伪装数据", key: -9 },
      ],
      addr: "",
      addrOptions: [],
      addrLoading: false,
      addrList: [],
      map: null,
      tip: '',
      loading: false,
      rules: {
        card_id: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: async (r, v, c, s) => {
              const uid = s.card_id;
              if (!uid || uid.length < 18) {
                return c(new Error("身份证号不正确！"));
              }
              c();
            },
          },
        ],
        cjzs: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: async (r, v, c, s) => {
              const uid = s.cjzs;
              if (!uid || uid.length < 16) {
                return c(new Error("残疾证号不正确！"));
              }
              c();
            },
          },
        ],
        phone: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: async (r, v, c, s) => {
              const uid = s.phone;
              if (!uid || uid.length < 11) {
                return c(new Error("手机号码不正确！"));
              }
              c();
            },
          },
        ],
        name: [
          { required: true, message: "不能为空！", trigger: "blur" },
          { min: 2, max: 256, message: "要求2～256个字符！", trigger: "blur" },
        ],
        birthday: [
          { required: true, message: "不能为空！", trigger: "blur" },
        ],
        rzsj: [
          { required: true, message: "不能为空！", trigger: "blur" },
        ],
        dy_live_id: [
          { required: true, message: "不能为空！", trigger: "blur" },
        ],
        mz: [
          { required: true, message: "不能为空！", trigger: "blur" },
        ],
        xl: [
          { required: true, message: "不能为空！", trigger: "blur" },
        ],
        hyzt: [
          { required: true, message: "不能为空！", trigger: "blur" },
        ],
        cjdj: [
          { required: true, message: "不能为空！", trigger: "blur" },
        ],
        province: [
          { required: true, message: "不能为空！", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.$nextTick(async () => {
      await this.initMap();

      this._.extend(this.form, this.amInfo);
      this.initAddr()
    });
  },
  beforeDestroy() {
    this.resetForm()
    this.addr =""
    this.addrOptions = []
    this.addrList = []
  },
  methods: {
    async onSubmit() {
     return this.$refs['form_edit'].validate(async(valid) => {
        if (!valid) {
          return false;
        }

        //console.log(JSON.stringify(this.form));
        const f = this.form;
        
       const rlt = await (f.id ? updateAnchorman(f.id, f) : createAnchorman(f))
       if(rlt.status != -1) {
        this.tip = `保存数据错误，${rlt.msg}。`
        return
       }

       this.$message({
         title: f.id ? '修改成功！' : '添加成功！',
         type: 'success'
       })
       //提示是否继续
       this.dismiss()
      })
    },
    initMap() {
      //定义地图中心点坐标
      const center = new TMap.LatLng(25.43197588002793, 101.73899656599349);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      this.map = new TMap.Map(this.$refs.tx_map_container, {
        center: center, //设置地图中心点坐标
        mapStyleId: "style2",
        zoom: 5.0, //设置地图缩放级别
        pitch: 43.5, //设置俯仰角,43.5
        viewMode: "3D",
        rotation: 0, //设置地图旋转角度
      });
    },
    async initAddr() {
      const [rlt, err] = await this.latlng2Addr(this.form['lat'], this.form['lng'])
      if (err) {
        return this.$message(`获取地址信息错误，${err}。`)
      }

      //console.log('latlng to addr %o', rlt);
      this.addrList.push(rlt)
      const pos = this.addrList.length - 1
      this.addrOptions.push({ label: `${rlt["title"]} - ${rlt["address"]}`, value: pos })
      this.addr = pos

      this.addMapMarker()
    },
    mzSearch(qs, cb) {
      var rlt = mz.filter(qs, (i) => {
        return { value: i["name"] };
      });

      cb(rlt);
    },
    async addrSearch(q) {
      //this.addrOptions = []

      if (!q || q.length < 3) {
        return;
      }

      this.addrLoading = true;

      const search = new TMap.service.Search({ pageSize: 20 });
      const rlt = await search.searchNearby({
        keyword: q,
        center: new TMap.LatLng(40.040589, 116.273543),
        autoExtend: true,
        radius: 99999,
      });

      this.addrLoading = false;
      if (rlt["status"] != 0) {
        this.$message(`查询地址发生错误：${rlt["message"]}。`);
        return;
      }

      /**
       * result:
       {"status": 0,
    "message": "query ok",
    "count": 423,
    "request_id": "b97f404b81611f0eb381395eab73d5b0",
    "data": [
        {
            "id": "14932351371474462635",
            "title": "羊册镇",
            "address": "河南省驻马店市泌阳县 ",
            "tel": "",
            "category": "地名地址:行政地名",
            "type": 0,
            "location": {
                "lat": 32.978164,
                "lng": 113.261287
            },
            "_distance": 830950.57,
            "ad_info": {
                "adcode": 411726,
                "province": "河南省",
                "city": "驻马店市",
                "district": "泌阳县"
            }
        },]
      }
       */
      this.addrList = rlt.data;
      this.addrOptions = rlt.data.map((v, i) => {
        return { label: `${v["title"]} - ${v["address"]}`, value: i };
      });

      this.addMapMarker()
    
    },
    onAddrChange(v) {
      const c = this.addrList[v];
      if (!c) return;

      this.addr = v
      
      const a = c["ad_info"];
      const l = c["location"];

      const center = new TMap.LatLng(l["lat"], l["lng"])
      this.map.panTo(center)

      //fill form
      this.form.province = a["province"];
      //{"c": "武山县", "p": "甘肃省", "s": "城关镇令川村", "t": "天水市"}
      this.form.addr = {
        p: a["province"],
        t: a["city"],
        c: a["district"],
        s: c["title"],
      };
      
      this.form.lat = l["lat"];
      this.form.lng = l["lng"];
      this.form.latlng = `${l["lat"]},${l["lng"]}`;

    },
    async latlng2Addr(lat,lng) {
      var geocoder = new TMap.service.Geocoder();
      var location = new TMap.LatLng(lat, lng)
      /**
       * address: "北京市顺义区李木路"
address_component: {nation: '中国', province: '北京市', city: '北京市', district: '顺义区', street: '李木路', …}
address_reference: {street_number: {…}, crossroad: {…}, town: {…}, street: {…}, landmark_l2: {…}}
formatted_addresses: {recommend: '顺义区李遂镇王辛庄', rough: '顺义区李遂镇王辛庄'}
location: t {lat: 40.12436, lng: 116.772749, height: 0}
       */
      const rlt = await geocoder
        .getAddress({ location: location }) // 将给定的坐标位置转换为地址
        
        if(rlt.status != 0){
          return [null, rlt.message]
        }

        const r = rlt['result']
        const a = r['address_component']

        //拼装成select使用的数据格式
        return [{
          "title": r['formatted_addresses']['recommend'],
          "address": a['province'] + a['city'] + a['district'],
          "location": r['location'],
          "ad_info": { province: a['province'], city: a['city'], district: a['district'], street: a['street'] }
        }]
    },
    addMapMarker() {
      const map = this.map, markers = [];
      this.addrList.forEach((i, idx) => {
        const l = i["location"];

        markers.push({
          // 点数组
          //id: i['id'],
          position: new TMap.LatLng(l["lat"], l["lng"]),
          //styleId: resolveIcon(i),
          properties: { idx: idx },
        });
      });

      const marker = new TMap.MultiMarker({
        map: map,
        styles: {
          highlight: new TMap.MarkerStyle({
            src:
              "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png",
          }),
        },
        geometries: markers,
      }).on("click", async (e) => {
        const idx = e["geometry"]["properties"]["idx"]
        this.onAddrChange(idx)
      });

      var bounds = new TMap.LatLngBounds();

      //设置自适应显示marker
      //判断标注点是否在范围内
      markers.forEach(function (item) {
        //若坐标点不在范围内，扩大bounds范围
        if (bounds.isEmpty() || !bounds.contains(item.position)) {
          bounds.extend(item.position);
        }
      })

      //设置地图可视范围
      setTimeout(() => {
        map.fitBounds(bounds, {
          padding: 10 // 自适应边距
        });
      }, 200);

      const editor = new TMap.tools.GeometryEditor({
        map, // 编辑器绑定的地图对象
        overlayList: [
          {
            overlay: marker, // 可编辑图层
            id: "marker",
            selectedStyleId: "highlight", // 被选中的marker会变为高亮样式
          },
        ],
        actionMode: TMap.tools.constants.EDITOR_ACTION.INTERACT, // 编辑器的工作模式
        activeOverlayId: "marker", // 激活图层
        selectable: true,
      });

      // editor.on('select', e => {
      //   console.log('on select %o', e);
      // })

      editor.on('adjust_complete', async e => {
        //console.log('on adjust_complete %o', e);
        const p = e['position']

        const [rlt, err] = await this.latlng2Addr(p['lat'], p['lng'])
        if (err) {
          return this.$message(`获取地址信息错误，${err}。`)
        }

        //console.log('latlng to addr %o', rlt);
        this.addrList.push(rlt)
        const pos = this.addrList.length - 1
        this.addrOptions.push({ label: `${rlt["title"]} - ${rlt["address"]}`, value: pos })
        this.addr = pos
      })
    },
    resetForm() {
      this.$refs["form_edit"].resetFields && this.$refs["form_edit"].resetFields();
      this.form = {
        name: "",
        gender: 2,
        birthday: Date.now(),
        phone: null,
        status: 0,
        province: null,
        addr: {},
        lat: 0,
        lng: 0,
        card_id: null,
        cjzs: null,
        cjlb: null,
        cjdj: null,
        xl: null,
        hyzt: null,
        mz: null,
        rzsj: null,
        dy_live_id: null,
      };
      this.formHash = hash(this.form);
    },
    dismiss() {
      this.$emit('cancel')
    }
  }
};
</script>
<style lang="scss">
.form_2col {
  display: flex;
  justify-content: space-between;
}
</style>
