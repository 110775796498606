<template>
  <div class="app-container">
    <div class="filter-container" >
      <el-input v-model="listQuery.name" placeholder="名字, 符号%模糊查询" style="width: 200px;" class="filter-item"
        @keyup.enter.native="handleFilter" :clearable="true"/>
<span style="padding-left: 10px" />
      <el-input
        v-model="listQuery.phone"
        placeholder="电话，默认右模糊查询"
        style="width: 200px;"
        class="filter-item"
        @keyup.enter.native="handleFilter"
        :clearable="true"
      />
      
      
      <span style="padding-left: 10px" />
      <el-select
        v-model="listQuery.state"
        placeholder="状态"
        clearable
        style="width: 90px"
        class="filter-item"
      >
        <el-option v-for="item in stateOptions" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>

      <span style="padding-left: 10px" />
      <el-button v-waves class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
      <el-button
        class="filter-item"
        style="margin-left: 10px;"
        type="primary"
        icon="el-icon-edit"
        @click="handleCreate"
      >
        添加博主
      </el-button>
    </div>
    <el-divider style="margin-bottom:10px"/>
    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      stripe
      height="640"
      highlight-current-row
      style="width: 100%;"
      element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(10, 10, 10, 0.7)"
      @sort-change="sortChange"
      @row-click="rowClick"
    >
      <!--el-table-column :label="$t('table.id')" prop="id" sortable="custom" align="left" width="80" :class-name="getSortClass('id')">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column-->
      <el-table-column
        type="index"
        width="50"
      />

      <el-table-column label="名字" width="210px" align="left">
        <template slot-scope="{row}">
          <el-avatar shape="square" size="small" :src="row.avatar_uri" style="position:absolute;"/>
          <span style="padding-left:40px">{{ row.name }}</span>
          <span style="color: #909399;font-size:0.8rem">
{{ row.gender | gender }}
{{ row.birthday | age }}岁
{{ row.mz | mz }}
          </span>
          
        </template>
      </el-table-column>

      <el-table-column label="网名" width="250px" align="left">
        <template slot-scope="{row}">
          <span >{{ row.nickname }}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="省份" prop="province" sortable="custom" :class-name="getSortClass('province')" width="110px" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.province }}</span>
        </template>
      </el-table-column>

      <el-table-column label="电话" width="110px" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="残疾" prop="cjlb" sortable="custom" :class-name="getSortClass('cjlb')" width="80">
        <template slot-scope="{row}">
          {{ row.cjlb }}{{row.cjdj}}级
        </template>
      </el-table-column>

      <el-table-column label="入职时间" prop="rzsj" sortable="custom" :class-name="getSortClass('rzsj')" min-width="190px" align="center">
        <template slot-scope="{row}">
          <span style="">{{ row.rzsj | rzsjFilter }}</span>
        </template>
      </el-table-column>
<el-table-column label="抖音号" width="110px" align="left">
  <template slot-scope="scope">
    <span>{{ scope.row.dyid }}</span>
  </template>
</el-table-column>
      <el-table-column label="状态" prop="status" sortable="custom" :class-name="getSortClass('status') + ', status-col'" width="80">
        <template slot-scope="{row}">
          <el-tag :type="row.status | statusFilter">
            {{ row.status | stateLabelFilter }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="160" class-name="small-padding fixed-width">
        <template slot-scope="{row}">
          <el-button type="primary" size="mini" @click.stop="handleEdit(row.id)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.pageSize" @pagination="getList" />
  <add ref="add"/>
      <editor ref="editor" @refresh="getList"/>
      <am-info ref="amInfo" />
  </div>
</template>

<script>
import { fetchAnchormans } from '@/api/anchorman'
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import editor from './components/editor.vue'
import add from './components/add.vue'
import amInfo from '@/components/amInfo'
import dayjs from 'dayjs'

const genderMap = ['未知', '女', '男']
export default {
  name: 'AmList',
  components: { Pagination, editor, amInfo, add },
  directives: { waves },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: 'success',
        '-1': 'danger',
        '-9': 'danger'
      }
      return statusMap[status]
    },
    stateLabelFilter(state) {
      const labelMap = {
        0: '正常',
        '-1': '解约',
        '-9': '伪装'
      }
      return labelMap[state]
    },
    rzsjFilter(r) {
      return dayjs(r).format('YY年M月D日')
    },
    gender(v) {
      return genderMap[v]
    },
    age(v) {
      return dayjs().diff(new Date(v), 'year')
    },
    mz(v) {
      return !v ? '未知' : (v.endsWith('族') ? v: v +'族')
    }
  },
  data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20,
        state: undefined,
        name: undefined,
        phone: '',
        sort: [],
      },
      stateOptions: [{ label: '正常', key: 0 }, { label: '解约', key: '-1' }, { label: '伪装', key: '-9' }]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.listLoading = true

      const q = [], l = this.listQuery
      if(l['name']) {
        q.push(['name', 'like', l['name']])
      }
      if (l['phone']) {
        (l['phone'].indexOf('%') == -1) && (l['phone'] += '%')//增加右模糊查询

        q.push(['phone', 'like', l['phone']])
      }
      if (l['state'] != undefined && l['state'] != '') {
        q.push(['status', '=', l['state']])
      }

      const rlt = await fetchAnchormans(q,this.listQuery.sort, [this.listQuery.page, this.listQuery.pageSize])
      setTimeout(() => {
        this.listLoading = false
      }, 200)
      if(rlt.status != -1) {
        this.$message({type: 'error', title: rlt['msg']})
        return
      }


      this.list = rlt['content']
      this.total = rlt['total']
      //console.log(this.total,this.list);
      
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleEdit(uid) {
      this.$refs.editor.show(uid)
    },
    handleCreate() {
      this.$refs.add.show()
    },
    handleDelete(row) {
      this.$notify({
        title: '成功',
        message: '删除成功',
        type: 'success',
        duration: 2000
      })
      const index = this.list.indexOf(row)
      this.list.splice(index, 1)
    },
    rowClick(row) {
      this.$refs.amInfo.show(row)
    },
    sortChange(data) {
      const { prop, order } = data

      let sort = this.listQuery.sort
      const _ap = `${prop}`
      const _dp = `${prop} DESC`
      if (order === 'ascending') {
        sort = this._.without(sort, _dp)
        if (!sort.includes(_ap)) { sort.push(_ap) }
      } else if (order === 'descending') {
        sort = this._.without(sort, _ap)
        if (!sort.includes(_dp)) { sort.push(_dp) }
      } else {
        sort = this._.without(sort, _ap, _dp)
      }

      this.listQuery.sort = sort
      this.handleFilter()
    },
    sortByID(order) {
      if (order === 'ascending') {
        this.listQuery.sort = 'id'
      } else {
        this.listQuery.sort = 'id DESC'
      }
      this.handleFilter()
    },
    getSortClass: function(key) {
      const sort = this.listQuery.sort
      return sort === `${key}`
        ? 'ascending'
        : sort === `${key} DESC`
          ? 'descending'
          : ''
    }
  }
}
</script>
<style scoped>
a{
  color:white
}
</style>
